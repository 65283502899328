import { useState } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { useUser } from "src/auth"
import { GlowButton, GlowCard, GlowFlexbox, GlowLink, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { useModal } from "src/hooks/modal"

import {
  HouseholdMembersCard_household$data,
  HouseholdMembersCard_household$key,
} from "./__generated__/HouseholdMembersCard_household.graphql"
import { HouseholdMembersCardCancelInviteMutation } from "./__generated__/HouseholdMembersCardCancelInviteMutation.graphql"
import { InviteModal, useInviteModal } from "./InviteModal/InviteModal"

import Avatar from "../Avatar/Avatar"

type HouseholdMembersCardProps = {
  household: HouseholdMembersCard_household$key
}

const HouseholdMembersCard = ({
  household: householdFragmentKey,
}: HouseholdMembersCardProps) => {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment HouseholdMembersCard_household on Household {
        members {
          id
          user {
            ...Avatar_user
            firstName
            lastName
            id
            email
          }
        }
        pendingInvites {
          id
          ...Avatar_invite
          firstName
          lastName
          email
          ...InviteModalPendingInvite_invite
        }
      }
    `,
    householdFragmentKey,
  )

  const user = useUser()
  const inviteModal = useInviteModal()
  const cancelInviteModal = useModal()

  const [selectedInviteToCancel, setSelectedInviteToCancel] = useState<
    HouseholdMembersCard_household$data["pendingInvites"][number] | null
  >(null)

  return (
    <>
      <GlowCard
        margin={{
          sm: "4",
          md: {
            x: "4",
            top: "6",
            bottom: "4",
          },
        }}
      >
        <GlowFlexbox direction="column" gap="6" alignItems="center">
          <GlowText textAlign="center" fontWeight="medium">
            <FormattedMessage
              id="householdMembersCard.header"
              defaultMessage="Add or manage other bill payers in your household"
            />
          </GlowText>

          {household.members.map((member) => {
            if (!member.user) {
              return null
            }

            return (
              <GlowCard colour="transparent" key={member.id} className="w-full">
                <GlowFlexbox gap="6" alignItems="center">
                  <Avatar user={member.user} size="base" />

                  <GlowFlexbox direction="column" gap="1" alignItems="start">
                    <GlowFlexbox gap="1">
                      <MemberOrInviteeName
                        firstName={member.user.firstName}
                        lastName={member.user.lastName}
                      />

                      {member.user.id === user?.id && (
                        <GlowText fontWeight="bold">
                          <FormattedMessage
                            id="householdMembersCard.members.currentUser"
                            defaultMessage="(You)"
                          />
                        </GlowText>
                      )}
                    </GlowFlexbox>

                    <GlowText size="sm" className="text-gray-500/64">
                      {member.user.email}
                    </GlowText>
                  </GlowFlexbox>
                </GlowFlexbox>
              </GlowCard>
            )
          })}

          {household.pendingInvites.map((invite) => (
            <GlowCard colour="transparent" key={invite.id} className="w-full">
              <GlowFlexbox alignItems="center" justifyContent="space-between">
                <GlowFlexbox gap="6" alignItems="center" justifyContent="start">
                  <Avatar invite={invite} size="base" />

                  <GlowFlexbox direction="column" gap="1" alignItems="start">
                    <GlowFlexbox gap="1">
                      <MemberOrInviteeName
                        firstName={invite.firstName}
                        lastName={invite.lastName}
                      />

                      <GlowText fontWeight="bold">
                        <FormattedMessage
                          id="householdMembersCard.pendingInvites.invited"
                          defaultMessage="(Invited)"
                        />
                      </GlowText>
                    </GlowFlexbox>

                    <GlowText size="sm" className="text-gray-500/64">
                      {invite.email}
                    </GlowText>
                  </GlowFlexbox>
                </GlowFlexbox>

                <GlowLink
                  label={intl.formatMessage({
                    id: "householdMembersCard.pendingInvites.cancelButton",
                    defaultMessage: "Cancel",
                  })}
                  onClick={() => {
                    setSelectedInviteToCancel(invite)
                    cancelInviteModal.open()
                  }}
                />
              </GlowFlexbox>
            </GlowCard>
          ))}

          <GlowButton
            variant={household.members.length ? "secondary" : "primary"}
            size="sm"
            label={intl.formatMessage({
              id: "householdMembersCard.members.add",
              defaultMessage: "Add another household member",
            })}
            iconName="add_regular"
            className="w-full"
            onClick={inviteModal.onOpen}
          />
        </GlowFlexbox>
      </GlowCard>

      <InviteModal query={inviteModal.query} onClose={inviteModal.onClose} />

      {selectedInviteToCancel && (
        <CancelMemberInviteModal
          isOpen={cancelInviteModal.isOpen}
          onClose={cancelInviteModal.close}
          selectedInvite={selectedInviteToCancel}
        />
      )}
    </>
  )
}

export default HouseholdMembersCard

type MemberOrInviteeNameProps = {
  firstName?: string | null
  lastName?: string | null
}

const MemberOrInviteeName = ({
  firstName,
  lastName,
}: MemberOrInviteeNameProps) => {
  return (
    <GlowText>
      <FormattedMessage
        id="householdMembersCard.memberOrInviteeName"
        defaultMessage="{firstName} {lastName}"
        values={{ firstName, lastName }}
      />
    </GlowText>
  )
}

type CancelMemberInviteModalProps = {
  isOpen: boolean
  onClose: () => void
  selectedInvite: HouseholdMembersCard_household$data["pendingInvites"][number]
}

const CancelMemberInviteModal = ({
  isOpen,
  onClose,
  selectedInvite,
}: CancelMemberInviteModalProps) => {
  const intl = useIntl()

  const [cancelInvite, cancellingInvite] =
    useMutation<HouseholdMembersCardCancelInviteMutation>(graphql`
      mutation HouseholdMembersCardCancelInviteMutation($id: ID!) {
        cancelHouseholdInviteV2(id: $id) {
          household {
            ...HouseholdMembersCard_household
          }
        }
      }
    `)

  return (
    <GlowModal
      isOpen={isOpen}
      onClose={onClose}
      disableManualClose={cancellingInvite}
      title={intl.formatMessage(
        {
          id: "cancelMemberInviteModal.confirmation.title",
          defaultMessage: "Are you sure?",
        },
        {
          firstName: selectedInvite.firstName,
          lastName: selectedInvite.lastName,
          email: selectedInvite.email,
        },
      )}
    >
      <GlowFlexbox direction="column" gap="4" alignItems="center">
        <GlowText textAlign="center">
          <FormattedMessage
            id="cancelMemberInviteModal.confirmation.text"
            defaultMessage="Are you sure you want to cancel the invite for {firstName} {lastName} ({email})?"
            values={{
              firstName: selectedInvite.firstName,
              lastName: selectedInvite.lastName,
              email: selectedInvite.email,
            }}
          />
        </GlowText>

        <GlowButton
          variant="primary"
          size="sm"
          label={intl.formatMessage({
            id: "cancelMemberInviteModal.confirmation.no",
            defaultMessage: "No, go back",
          })}
          onClick={onClose}
          className="w-full"
        />

        <GlowButton
          variant="secondary"
          size="sm"
          label={intl.formatMessage({
            id: "cancelMemberInviteModal.confirmation.yes",
            defaultMessage: "Yes, cancel invite",
          })}
          onClick={() =>
            cancelInvite({
              variables: { id: selectedInvite.id },
              onCompleted: onClose,
            })
          }
          className="w-full"
        />
      </GlowFlexbox>
    </GlowModal>
  )
}
