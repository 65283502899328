import { MessageDescriptor, defineMessage } from "react-intl"

import { GlowIconName } from "src/glow"

type NotificationContent = {
  title: MessageDescriptor
  description: MessageDescriptor
  iconName: GlowIconName
}

export const notificationContentByTypeRecord: Record<
  string,
  NotificationContent
> = {
  EnergyDocumentUploadUserNotification: {
    title: defineMessage({
      id: "userNotification.energyUploadDocument.title",
      defaultMessage: "Add a recent energy bill",
    }),
    description: defineMessage({
      id: "userNotification.energyUploadDocument.description",
      defaultMessage: "We need it to create your savings recommendation.",
    }),
    iconName: "touch_finger_1_regular",
  },
  ReviewEnergyRecommendationUserNotification: {
    title: defineMessage({
      id: "userNotification.reviewEnergyRecommendation.title",
      defaultMessage: "Review our recommendation",
    }),
    description: defineMessage({
      id: "userNotification.reviewEnergyRecommendation.description",
      defaultMessage:
        "You have {expiresAfter, plural, one {# day} other {# days}} to review our energy switch recommendation",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  ReviewMobileRecommendationUserNotification: {
    title: defineMessage({
      id: "userNotification.reviewMobileRecommendation.title",
      defaultMessage: "Review our recommendation",
    }),
    description: defineMessage({
      id: "userNotification.reviewMobileRecommendation.description",
      defaultMessage:
        "You have {expiresAfter, plural, one {# day} other {# days}} to review our mobile tariff recommendation",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  ReviewBroadbandRecommendationUserNotification: {
    title: defineMessage({
      id: "userNotification.reviewBroadbandRecommendation.title",
      defaultMessage: "Review our recommendation",
    }),
    description: defineMessage({
      id: "userNotification.reviewBroadbandRecommendation.description",
      defaultMessage:
        "You have {expiresAfter, plural, one {# day} other {# days}} to review our broadband tariff recommendation",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  SubmitBankAccountDetailsUserNotification: {
    title: defineMessage({
      id: "userNotification.submitBankAccountDetails.title",
      defaultMessage: "Submit bank account details",
    }),
    description: defineMessage({
      id: "userNotification.submitBankAccountDetails.description",
      defaultMessage: "We need it to complete your switch.",
    }),
    iconName: "lock_2_regular",
  },
  SavingsStatementCreatedUserNotification: {
    title: defineMessage({
      id: "userNotification.savingsStatementCreated.title",
      defaultMessage: "New statement",
    }),
    description: defineMessage({
      id: "userNotification.savingsStatementCreated.description",
      defaultMessage: "You have a new savings statement.",
    }),
    iconName: "saving_bank_1_regular",
  },
  UpdateHouseholdPreferencesUserNotification: {
    title: defineMessage({
      id: "userNotification.updateHouseholdPreferences.title",
      defaultMessage: "Submit household preferences",
    }),
    description: defineMessage({
      id: "userNotification.updateHouseholdPreferences.description",
      defaultMessage: "We need these to find deals that suit your household.",
    }),
    iconName: "love_it_settings_regular",
  },
  EnergyWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.energyWizard.title",
      defaultMessage: "Tell us about your energy",
    }),
    description: defineMessage({
      id: "userNotification.energyWizard.description",
      defaultMessage: "Find out how much you can save on your energy.",
    }),
    iconName: "flash_1_regular",
  },
  BroadbandWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.broadbandWizard.title",
      defaultMessage: "Tell us about your broadband",
    }),
    description: defineMessage({
      id: "userNotification.broadbandWizard.description",
      defaultMessage: "Find out how much you can save on your broadband.",
    }),
    iconName: "wifi_regular",
  },
  MobileWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.mobileWizard.title",
      defaultMessage: "Tell us about your mobile",
    }),
    description: defineMessage({
      id: "userNotification.mobileWizard.description",
      defaultMessage: "Find out how much you can save on your mobile.",
    }),
    iconName: "mobile_phone_regular",
  },
  MortgageWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.mortgageWizard.title",
      defaultMessage: "Tell us about your mortgage",
    }),
    description: defineMessage({
      id: "userNotification.mortgageWizard.description",
      defaultMessage: "Find out how much you can save on your mortgage.",
    }),
    iconName: "real_estate_insurance_house_regular",
  },
  DelegateManagementOfServicesUserNotification: {
    title: defineMessage({
      id: "userNotification.delegateManagementOfServices.title",
      defaultMessage: "Let us manage your household services",
    }),
    description: defineMessage({
      id: "userNotification.delegateManagementOfServices.description",
      defaultMessage: "We'll make sure you're on the best deals.",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  ConnectEmailUserNotification: {
    title: defineMessage({
      id: "userNotification.connectEmail.title",
      defaultMessage: "Connect your email",
    }),
    description: defineMessage({
      id: "userNotification.connectEmail.description",
      defaultMessage: "Save time with Nous AI.",
    }),
    iconName: "email_action_unread_regular",
  },
  ConnectWhatsAppUserNotification: {
    title: defineMessage({
      id: "userNotification.connectWhatsApp.title",
      defaultMessage: "Connect WhatsApp",
    }),
    description: defineMessage({
      id: "userNotification.connectWhatsApp.description",
      defaultMessage: "So we can contact you",
    }),
    iconName: "messaging_whatsapp",
  },
  TypeformDataCollectionUserNotification: {
    title: defineMessage({
      id: "userNotification.typeformDataCollectionUserNotification.title",
      defaultMessage: "We need some details from you",
    }),
    description: defineMessage({
      id: "userNotification.typeformDataCollectionUserNotification.description",
      defaultMessage: "Fill out this form so we can help.",
    }),
    iconName: "content_paper_edit_regular",
  },
  InviteHouseholdMembersUserNotification: {
    title: defineMessage({
      id: "userNotification.inviteHouseholdMembers.title",
      defaultMessage: "Invite other bill payers",
    }),
    description: defineMessage({
      id: "userNotification.inviteHouseholdMembers.description",
      defaultMessage: "Invite other bill payers to join your household.",
    }),
    iconName: "checkup_person_regular",
  },
}

export const notificationContentByTypeformFormType: Record<
  string,
  NotificationContent
> = {
  MOBILE_REGISTRATION_DATA: {
    title: defineMessage({
      id: "userTask.mobileRegistrationDataUserNotification.title",
      defaultMessage: "Answer these mobile questions",
    }),
    description: defineMessage({
      id: "userTask.mobileRegistrationDataUserNotification.description",
      defaultMessage: "So we can set up your new mobile account for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
  BROADBAND_REGISTRATION_DATA: {
    title: defineMessage({
      id: "userTask.broadbandRegistrationDataUserNotification.title",
      defaultMessage: "Answer these broadband questions",
    }),
    description: defineMessage({
      id: "userTask.broadbandRegistrationDataUserNotification.description",
      defaultMessage: "So we can set up your new broadband account for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
  ENERGY_PREFERENCES: {
    title: defineMessage({
      id: "userTask.energyPreferencesUserNotification.title",
      defaultMessage: "Answer these energy questions",
    }),
    description: defineMessage({
      id: "userTask.energyPreferencesUserNotification.description",
      defaultMessage: "So we can choose the best energy proposal for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
  MOBILE_PREFERENCES: {
    title: defineMessage({
      id: "userTask.mobilePreferencesUserNotification.title",
      defaultMessage: "Answer these mobile questions",
    }),
    description: defineMessage({
      id: "userTask.mobilePreferencesUserNotification.description",
      defaultMessage: "So we can choose the best mobile proposal for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
  BROADBAND_PREFERENCES: {
    title: defineMessage({
      id: "userTask.broadbandPreferencesUserNotification.title",
      defaultMessage: "Answer these broadband questions",
    }),
    description: defineMessage({
      id: "userTask.broadbandPreferencesUserNotification.description",
      defaultMessage: "So we can choose the best broadband proposal for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
  BROADBAND_PROPOSAL_DATA: {
    title: defineMessage({
      id: "userTask.broadbandProposalDataUserNotification.title",
      defaultMessage: "Tell us more about your current broadband contract",
    }),
    description: defineMessage({
      id: "userTask.broadbandProposalDataUserNotification.description",
      defaultMessage: "So we can find broadband deals for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
  MOBILE_PROPOSAL_DATA: {
    title: defineMessage({
      id: "userTask.mobileProposalDataUserNotification.title",
      defaultMessage: "Tell us more about your current mobile contract",
    }),
    description: defineMessage({
      id: "userTask.mobileProposalDataUserNotification.description",
      defaultMessage: "So we can find mobile deals for you.",
    }),
    iconName: "message_bubble_question_regular",
  },
}
