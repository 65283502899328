/**
 * @generated SignedSource<<97752dcd12aacaa3f716aa0cb527a6bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TypeformFormType = "BROADBAND_PREFERENCES" | "BROADBAND_PROPOSAL_DATA" | "BROADBAND_REGISTRATION_DATA" | "ENERGY_PREFERENCES" | "MOBILE_PREFERENCES" | "MOBILE_PROPOSAL_DATA" | "MOBILE_REGISTRATION_DATA" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useIsTypeformUserTask_userTask$data = {
  readonly __typename: "TypeformDataCollectionUserTask";
  readonly formType: TypeformFormType | null | undefined;
  readonly " $fragmentType": "useIsTypeformUserTask_userTask";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "useIsTypeformUserTask_userTask";
};
export type useIsTypeformUserTask_userTask$key = {
  readonly " $data"?: useIsTypeformUserTask_userTask$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsTypeformUserTask_userTask">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsTypeformUserTask_userTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formType",
          "storageKey": null
        }
      ],
      "type": "TypeformDataCollectionUserTask",
      "abstractKey": null
    }
  ],
  "type": "IUserTask",
  "abstractKey": "__isIUserTask"
};

(node as any).hash = "4427b15ccc50b5b4083695698da6dfcb";

export default node;
