import clsx from "clsx"

import { GlowText } from "src/glow"

export const SettingsPageNavButton = (props: {
  label: string
  active?: boolean
  onClick: () => void
}) => {
  return (
    <button onClick={props.onClick} className="group">
      <GlowText
        className={clsx(
          props.active || "group-hover:text-gray-500/100",
          props.active ? "text-gray-500" : "text-gray-500/64",
        )}
        fontWeight={props.active ? "medium" : "normal"}
      >
        {props.label}
      </GlowText>
    </button>
  )
}
