/**
 * @generated SignedSource<<ea63739d4bfd13062651ab06f62721f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageMembers_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HouseholdMembersCard_household">;
  readonly " $fragmentType": "SettingsPageSubPageMembers_household";
};
export type SettingsPageSubPageMembers_household$key = {
  readonly " $data"?: SettingsPageSubPageMembers_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageMembers_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSubPageMembers_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HouseholdMembersCard_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "5f53cfd0aaac8e01e8d0650bddd9fafb";

export default node;
