import { useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import HouseholdMembersCard from "src/components/HouseholdMembers/HouseholdMembersCard"

import { SettingsPageSubPageMembers_household$key } from "./__generated__/SettingsPageSubPageMembers_household.graphql"
import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

import { titleMap } from "../utils"

export const SettingsPageSubPageMembers = (props: {
  household: SettingsPageSubPageMembers_household$key
  onBackToOverview: () => void
}) => {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment SettingsPageSubPageMembers_household on Household {
        ...HouseholdMembersCard_household
      }
    `,
    props.household,
  )

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage(titleMap.members)}
      onBackToOverview={props.onBackToOverview}
    >
      <HouseholdMembersCard household={household} />
    </SettingsPageSubPageLayout>
  )
}
