import { useNavigate } from "@tanstack/react-router"
import { useIntl } from "react-intl"

import { GlowButton } from "src/glow"
import { Viewport, useViewport } from "src/hooks/responsive"
import useTracking from "src/tracking/useTracking"

export default function InviteMemberButton() {
  const intl = useIntl()
  const track = useTracking()
  const viewport = useViewport()
  const navigate = useNavigate()
  const isMobile = viewport <= Viewport.SM

  return (
    <>
      <GlowButton
        variant="tertiary"
        iconName="checkup_person_regular"
        size="sm"
        label={
          isMobile
            ? intl.formatMessage({
                id: "inviteMemberButton.mobile",
                defaultMessage: "Invite a household member",
              })
            : intl.formatMessage({
                id: "inviteMemberButton.desktop",
                defaultMessage: "Invite",
              })
        }
        onClick={() => {
          track(["Invite", "Started"])
          navigate({
            to: "/settings/",
          })
        }}
      />
    </>
  )
}
