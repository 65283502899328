/**
 * @generated SignedSource<<56c1fac19bdaebe2b7772796a16fa614>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Avatar_user$data = {
  readonly firstName: string | null | undefined;
  readonly initials: string;
  readonly " $fragmentType": "Avatar_user";
} | null | undefined;
export type Avatar_user$key = {
  readonly " $data"?: Avatar_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Avatar_user",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initials",
        "storageKey": null
      },
      "action": "NONE"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8c7be10a10d7d217a7c8a328db06bb0f";

export default node;
