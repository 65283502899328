import { graphql, useFragment } from "react-relay"

import { Tooltip } from "src/components/Tooltip/Tooltip"
import { GlowIcon } from "src/glow"

import { InviteModalPendingInvite_invite$key } from "./__generated__/InviteModalPendingInvite_invite.graphql"

import Avatar from "../../Avatar/Avatar"

type Props = {
  invite: InviteModalPendingInvite_invite$key
  onCancel: () => void
}
export default function InviteModalPendingInvite({
  invite: inviteKey,
  onCancel,
}: Props) {
  const invite = useFragment(
    graphql`
      fragment InviteModalPendingInvite_invite on HouseholdMemberInvite {
        ...Avatar_invite
        displayName
        email
      }
    `,
    inviteKey,
  )
  return (
    <div
      className="flex items-center justify-between"
      data-testid="pending-invite"
    >
      <div className="flex items-center justify-start space-x-3">
        <Avatar invite={invite} size="sm" />
        <div className="flex flex-col">
          {invite.displayName && (
            <p className="text-sm font-medium">{invite.displayName}</p>
          )}
          <p className="text-sm">{invite.email}</p>
        </div>
      </div>
      <Tooltip text="Cancel invite" pointer>
        <GlowIcon name="close_regular" className="w-4" onClick={onCancel} />
      </Tooltip>
    </div>
  )
}
