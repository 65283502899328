import clsx from "clsx"
import { useIntl } from "react-intl"

import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum } from "src/types"
import { serviceLevelBenefitsMap } from "src/utils/serviceLevelBenefitsMap"

export const ServiceLevelSelectBenefits = ({
  selectedServiceLevel,
}: {
  selectedServiceLevel: CheckedRelayEnum<NousServiceLevel>
}) => {
  const intl = useIntl()

  return (
    <GlowFlexbox
      direction={{ sm: "column", md: "row" }}
      gap="6"
      alignItems="start"
    >
      {serviceLevelBenefitsMap[selectedServiceLevel].map((benefit, index) => (
        <GlowFlexbox
          direction={{ sm: "row", md: "column" }}
          alignItems="center"
          gap="3"
          key={index}
          className="w-full md:w-1/3"
        >
          <GlowIcon
            name={benefit.icon}
            className={clsx(
              "h-6 w-6 shrink-0",
              benefit.missed && "text-angsty-red-400",
            )}
          />
          <GlowText
            size="sm"
            textAlign={{
              sm: "left",
              md: "center",
            }}
          >
            {intl.formatMessage(benefit.text)}
          </GlowText>
        </GlowFlexbox>
      ))}
    </GlowFlexbox>
  )
}
