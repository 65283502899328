import React from "react"
import { useCallback, useEffect, useMemo, useRef } from "react"

import clsx from "clsx"

import { useViewport, Viewport } from "src/hooks/responsive"
import useEscListener from "src/hooks/useEscListener"
import useEventListener from "src/hooks/useEventListener"
import useToggle from "src/hooks/useToggle"

import { GlowText } from "./GlowText"

export type Position = "top" | "bottom" | "left" | "right"

type CommonProps = {
  children: React.ReactNode
  handle: React.ReactNode
  className?: string
  overridePosition?: Position
}

type GlowTooltipProps = CommonProps &
  (
    | {
        isOpen?: never
        onToggle?: (isOpen: boolean) => void
      }
    | {
        isOpen: boolean
        onToggle: (isOpen: boolean) => void
      }
  )

const positonRecord = {
  top: "bottom-full left-1/2 transform -translate-x-1/2",
  bottom: "top-full left-1/2 transform -translate-x-1/2",
  left: "right-full",
  right: "left-full",
}

export function GlowTooltip(props: GlowTooltipProps) {
  const internalToggle = useToggle()

  const { isOpen, toggle } = useMemo(() => {
    const propsOnToggle = props.onToggle
    if (typeof props.isOpen === "boolean") {
      return {
        isOpen: props.isOpen,
        toggle: () => {
          propsOnToggle?.(!props.isOpen)
        },
      }
    } else {
      return {
        isOpen: internalToggle.value,
        toggle: () => {
          const internalToggleFn = internalToggle.toggle
          internalToggleFn()
          propsOnToggle?.(!internalToggle.value)
        },
      }
    }
  }, [
    internalToggle.value,
    internalToggle.toggle,
    props.isOpen,
    props.onToggle,
  ])

  const isMobile = useViewport() < Viewport.MD
  const closer = useCallback(() => {
    if (isOpen) {
      toggle()
    }
  }, [isOpen, toggle])
  useEscListener(closer)

  const tooltipRef = useRef<HTMLDivElement>(null)

  const [tooltipPosition, setTooltipPosition] = React.useState<Position>(
    props.overridePosition ?? "top",
  )

  const checkInbounds = (element: DOMRect) =>
    element.top >= 0 &&
    element.left >= 0 &&
    element.bottom <= window.innerHeight &&
    element.right <= window.innerWidth

  const handlePosition = React.useCallback(() => {
    const dimensions = tooltipRef.current?.getBoundingClientRect()

    if (!dimensions) {
      return
    }
    if (checkInbounds(dimensions)) {
      return
    }

    // if top out of bounds
    if (dimensions.top < 0) {
      setTooltipPosition("bottom")
      return
    }
    // if right out of bounds
    if (dimensions.right > window.innerWidth) {
      setTooltipPosition("left")
      return
    }
    // if left out of bounds
    if (dimensions.left < 0) {
      setTooltipPosition("right")
      return
    }
    // If bottom out of bounds
    if (dimensions.bottom > window.innerHeight) {
      setTooltipPosition("top")
      return
    }
  }, [])

  const clickListener = (event: MouseEvent) => {
    const eventTarget = event.target
    if (
      isOpen &&
      tooltipRef.current &&
      eventTarget instanceof HTMLElement &&
      !tooltipRef.current.contains(eventTarget)
    ) {
      toggle()
    }
  }

  useEffect(() => {
    if (props.overridePosition) {
      return
    }
    handlePosition()
  }, [props.overridePosition, handlePosition])

  useEventListener({
    event: "click",
    handler: clickListener,
  })

  useEventListener({
    event: "resize",
    handler: handlePosition,
  })

  useEventListener({
    event: "scroll",
    handler: handlePosition,
  })

  const tooltipClasses = clsx(
    "absolute z-tooltip bg-black text-white shadow-lg rounded-lg p-2 text-xs",
    "w-max max-w-[200px] md:max-w-[320px] text-wrap",
  )

  const isControlled = typeof props.isOpen === "boolean"

  if (isMobile) {
    return (
      <div className={clsx("relative flex", props.className)}>
        {isControlled ? (
          <div>{props.handle}</div>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation()
              toggle()
            }}
          >
            {props.handle}
          </button>
        )}

        {isOpen && (
          <div
            ref={tooltipRef}
            role="tooltip"
            className={clsx(tooltipClasses, positonRecord[tooltipPosition])}
          >
            <GlowText
              size="xs"
              textAlign="center"
              className="flex flex-col items-center"
            >
              {props.children}
            </GlowText>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={clsx("group", props.className)}>
      <div className={clsx("relative flex cursor-pointer")}>
        <div>{props.handle}</div>

        <div
          ref={tooltipRef}
          role="tooltip"
          className={clsx(
            tooltipClasses,
            isControlled
              ? props.isOpen
                ? "flex"
                : "hidden"
              : "hidden group-hover:flex",
            positonRecord[tooltipPosition],
          )}
        >
          <GlowText
            size="xs"
            textAlign="center"
            className="flex flex-col items-center"
          >
            {props.children}
          </GlowText>
        </div>
      </div>
    </div>
  )
}
