import React from "react"

import { useNavigate } from "@tanstack/react-router"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import { logger } from "src/logger"
import useTracking from "src/tracking/useTracking"

import { UserNotification_notification$key } from "./__generated__/UserNotification_notification.graphql"
import { UserNotificationMarkUserNotificationAsReadMutation } from "./__generated__/UserNotificationMarkUserNotificationAsReadMutation.graphql"
import NotificationPriority from "./NotificationPriority"
import {
  notificationContentByTypeformFormType,
  notificationContentByTypeRecord,
} from "./notificationsContent"
import relativeDifferenceTimeWithUnit from "./relativeDifferenceTimeWithUnit"

import useIsTypeformUserTask from "../UserTask/hooks/useIsTypeformUserTask"

type UserNotificationProps = {
  className?: string
  forceMobileSpacing?: boolean
  notification: UserNotification_notification$key
}

const supportedNotificationsTypes = [
  "EnergyDocumentUploadUserNotification",
  "ReviewEnergyRecommendationUserNotification",
  "ReviewMobileRecommendationUserNotification",
  "ReviewBroadbandRecommendationUserNotification",
  "SubmitBankAccountDetailsUserNotification",
  "SavingsStatementCreatedUserNotification",
  "UpdateHouseholdPreferencesUserNotification",
  "EnergyWizardUserNotification",
  "BroadbandWizardUserNotification",
  "MobileWizardUserNotification",
  "MortgageWizardUserNotification",
  "DelegateManagementOfServicesUserNotification",
  "ConnectEmailUserNotification",
  "ConnectWhatsAppUserNotification",
  "TypeformDataCollectionUserNotification",
  "InviteHouseholdMembersUserNotification",
]
const notificationsTypeWithTask = [
  "EnergyDocumentUploadUserNotification",
  "ReviewEnergyRecommendationUserNotification",
  "ReviewMobileRecommendationUserNotification",
  "ReviewBroadbandRecommendationUserNotification",
  "SubmitBankAccountDetailsUserNotification",
  "UpdateHouseholdPreferencesUserNotification",
  "EnergyWizardUserNotification",
  "BroadbandWizardUserNotification",
  "MobileWizardUserNotification",
  "MortgageWizardUserNotification",
  "ConnectEmailUserNotification",
  "ConnectWhatsAppUserNotification",
  "TypeformDataCollectionUserNotification",
  "InviteHouseholdMembersUserNotification",
]
const notificationsTypeWithSavingsStatement = [
  "SavingsStatementCreatedUserNotification",
]

export default function UserNotification({
  className,
  forceMobileSpacing,
  notification: notificationKey,
}: UserNotificationProps) {
  const intl = useIntl()
  const track = useTracking()
  const notification = useFragment(
    graphql`
      fragment UserNotification_notification on IUserNotification {
        __typename
        id
        priority
        read
        sentAt
        ... on EnergyDocumentUploadUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on ReviewEnergyRecommendationUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            ... on ReviewEnergyRecommendationUserTask {
              dealCardExpiresAfterDays
            }
            quest {
              id
            }
          }
        }
        ... on ReviewMobileRecommendationUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            ... on ReviewMobileRecommendationUserTask {
              dealCardExpiresAfterDays
            }
            quest {
              id
            }
          }
        }
        ... on ReviewBroadbandRecommendationUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            ... on ReviewBroadbandRecommendationUserTask {
              dealCardExpiresAfterDays
            }
            quest {
              id
            }
          }
        }
        ... on SubmitBankAccountDetailsUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on SavingsStatementCreatedUserNotification {
          savingsStatement {
            id
          }
        }
        ... on BroadbandWizardUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on MobileWizardUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on MortgageWizardUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on UpdateHouseholdPreferencesUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on EnergyWizardUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on DelegateManagementOfServicesUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on ConnectEmailUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on ConnectWhatsAppUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on TypeformDataCollectionUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
        ... on InviteHouseholdMembersUserNotification {
          task {
            id
            ...useIsTypeformUserTask_userTask
            quest {
              id
            }
          }
        }
      }
    `,
    notificationKey,
  )

  const { isTypeformTask, typeformFormType } = useIsTypeformUserTask({
    userTask: notification.task ?? null,
  })

  const content =
    isTypeformTask && typeformFormType !== null
      ? notificationContentByTypeformFormType[typeformFormType]
      : notificationContentByTypeRecord[notification.__typename]

  const [viewed, setViewed] = React.useState(false)

  const onViewed = React.useCallback(async () => {
    if (viewed) {
      return
    }

    await track([
      "Notification",
      "Viewed",
      {
        type: notification.__typename,
        id: notification.id,
      },
    ])
    setViewed(true)
  }, [notification.__typename, notification.id, track, viewed])

  React.useEffect(() => {
    return () => {
      onViewed()
    }
  }, [onViewed])

  const [markNotificationAsRead] =
    useMutation<UserNotificationMarkUserNotificationAsReadMutation>(graphql`
      mutation UserNotificationMarkUserNotificationAsReadMutation(
        $input: MarkUserNotificationAsReadInput!
      ) {
        markUserNotificationAsRead(input: $input) {
          userNotification {
            ...UserNotification_notification
          }
          household {
            ...NotificationNav_household
          }
        }
      }
    `)

  const spacer = "·"

  const notificationWithTask =
    notificationsTypeWithTask.includes(notification.__typename) &&
    notification.task

  const notificationWithSavingsStatement =
    notificationsTypeWithSavingsStatement.includes(notification.__typename) &&
    notification.savingsStatement

  const timeDiff = React.useMemo(
    () => relativeDifferenceTimeWithUnit(new Date(notification.sentAt ?? "")),
    [notification.sentAt],
  )
  const navigate = useNavigate()

  if (!supportedNotificationsTypes.includes(notification.__typename)) {
    return null
  }

  return (
    <button
      onClick={() => {
        track([
          "Notification",
          "Clicked",
          {
            id: notification.id,
            type: notification.__typename,
          },
        ])

        markNotificationAsRead({
          variables: {
            input: {
              id: notification.id,
            },
          },
          optimisticUpdater: (store) => {
            const userNotification = store.get(notification.id)
            if (userNotification) {
              userNotification.setValue(true, "read")
            }
          },
          onCompleted: () => {
            logger.info("Successfully marked notification as read", {
              type: notification.__typename,
              id: notification.id,
            })
            if (notificationWithTask) {
              navigate({
                to: "/quests/$questId/tasks/$taskId",
                params: {
                  questId: notification.task.quest?.id ?? "",
                  taskId: notification.task.id,
                },
              })
            }
            if (notificationWithSavingsStatement) {
              navigate({
                to: "/statement/$id",
                params: { id: notification.savingsStatement.id },
              })
            }
          },
          onError: (error) => {
            logger.error("Failed to mark notification as read", {
              id: notification.id,
              type: notification.__typename,
              error,
            })
          },
        })
      }}
      className={clsx(
        "group w-full",
        notification.read ? "bg-white" : "bg-nous-glow-100",
        notification.read ? "hover:bg-gray-50" : "hover:bg-nous-glow-300",
        className,
      )}
    >
      <GlowFlexbox
        direction="row"
        alignItems="start"
        justifyContent="start"
        className={clsx(
          forceMobileSpacing ? "gap-3 p-4" : "gap-3 p-4 md:gap-6 md:p-6",
        )}
      >
        <div
          className={clsx(
            "grid shrink-0 place-items-center rounded-full",
            forceMobileSpacing ? "h-8 w-8" : "h-8 w-8 md:h-12 md:w-12",
            notification.__typename ===
              "ReviewEnergyRecommendationUserNotification" && "radial-gradient",
            notification.read ? "bg-gray-50" : "bg-white",
            "group-hover:bg-white",
          )}
        >
          <GlowIcon
            name={content.iconName}
            className={clsx(
              forceMobileSpacing ? "h-4 w-4" : "h-4 w-4 md:h-6 md:w-6",
              "shrink-0",
            )}
          />
        </div>

        <GlowFlexbox direction="column" alignItems="start">
          <GlowText
            size="sm"
            fontWeight="bold"
            textAlign="left"
            className="group-hover:underline"
          >
            {intl.formatMessage(content.title)}
          </GlowText>
          <GlowText size="xs" textAlign="left" className="text-off-black-64">
            {intl.formatMessage(content.description, {
              expiresAfter: notification.task?.dealCardExpiresAfterDays,
            })}
          </GlowText>
          <GlowFlexbox
            direction="row"
            justifyContent="start"
            alignItems="center"
            className="mt-2"
          >
            {notification.priority && notification.priority !== "GENERIC" && (
              <GlowFlexbox
                direction="row"
                gap="1"
                justifyContent="start"
                alignItems="center"
                margin={{ right: "1" }}
              >
                <NotificationPriority priority={notification.priority} />
                <GlowText size="xs" className="text-off-black-64">
                  {spacer}
                </GlowText>
              </GlowFlexbox>
            )}
            {notificationWithTask && (
              <GlowFlexbox
                direction="row"
                gap="1"
                justifyContent="start"
                alignItems="center"
                margin={{ right: "1" }}
              >
                <GlowText
                  size="xs"
                  fontWeight="medium"
                  className="text-off-black-64"
                >
                  <FormattedMessage
                    id="notificationItem.withTask.text"
                    defaultMessage="To-do"
                  />
                </GlowText>
                {notification.sentAt && (
                  <GlowText size="xs" className="text-off-black-64">
                    {spacer}
                  </GlowText>
                )}
              </GlowFlexbox>
            )}
            {notificationWithSavingsStatement && (
              <GlowFlexbox
                direction="row"
                gap="1"
                justifyContent="start"
                alignItems="center"
                margin={{ right: "1" }}
              >
                <GlowText
                  size="xs"
                  fontWeight="medium"
                  className="text-off-black-64"
                >
                  <FormattedMessage
                    id="notificationItem.withSavingsStatement.text"
                    defaultMessage="View"
                  />
                </GlowText>
                {notification.sentAt && (
                  <GlowText size="xs" className="text-off-black-64">
                    {spacer}
                  </GlowText>
                )}
              </GlowFlexbox>
            )}
            {notification.sentAt && (
              <GlowText
                size="xs"
                fontWeight="medium"
                className="text-off-black-64"
              >
                {intl.formatRelativeTime(timeDiff.diff, timeDiff.unit, {
                  style: "long",
                })}
              </GlowText>
            )}
          </GlowFlexbox>
        </GlowFlexbox>
        <span
          className={clsx(
            "ml-auto h-3 w-3 shrink-0 rounded-full",
            notification.read ? "bg-gray-200" : "bg-nous-glow-400",
          )}
        />
      </GlowFlexbox>
    </button>
  )
}
