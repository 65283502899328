import { SavingsReportCategoryDetailsSection } from "./types"

export const emailProviders = ["Google", "Microsoft"] as const

export const recommendationSections = [
  "Expired",
  "Welcome",
  "Main",
  "How It Works",
  "Trustpilot Reviews",
  "Tariff Details",
  "Savings",
  "Ready To Switch",
  "2nd Call To Action",
  "FAQs",
  "Trust Nous",
  "Learn More",
  "Footer Disclaimer",
  "Call To Action Variant",
  "What Happens Next",
  "Benefits",
  "Important To Know",
] as const

export const savingsReportCategories = [
  "Broadband",
  "Energy",
  "Mobile",
  "Mortgage",
] as const

const savingsReportCategoryDetailsSections: ReadonlyArray<SavingsReportCategoryDetailsSection> =
  [
    "Broadband details",
    "Energy details",
    "Mobile details",
    "Mortgage details",
  ] as const

export const savingsReportFullSections = [
  "Summary",
  ...savingsReportCategoryDetailsSections,
  "Footer",
] as const

export const userTaskItemContext = [
  "Dashboard To-Do List",
  "Up Next component",
  "Task Page",
  "Details Page",
] as const

export const userTaskViewAllContext = [
  "Savings Quests",
  "Setup Quests",
] as const

export const serviceLevels = [
  "DO_EVERYTHING_MYSELF",
  "DO_MOST_OF_IT_FOR_ME",
  "DO_ALL_OF_IT_FOR_ME",
] as const

export const bankDetailsEntryMode = ["Add", "Edit"] as const

export const categories = [
  "Broadband",
  "Car Insurance",
  "Council Tax",
  "Energy",
  "Home Insurance",
  "Mobile",
  "Mortgage",
  "Television",
  "Water",
] as const

export const budgetWidgetCtaContext = [
  "Callout banner",
  "Footer",
  "Module footer",
  "Carousel mid",
  "Carousel end",
] as const

export const undelegationReasons = [
  "WANT_MORE_CONTROL",
  "DID_NOT_LIKE_PROPOSED_DEALS",
  "DID_NOT_UNDERSTAND_DELEGATION",
  "DOES_NOT_TRUST_NOUS",
  "OTHER",
] as const
