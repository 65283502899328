import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import EnergyRecommendationPageQuery, {
  EnergyRecommendationPageQuery as EnergyRecommendationPageQueryType,
} from "src/pages/Recommendations/EnergyRecommendationPage/__generated__/EnergyRecommendationPageQuery.graphql"

const searchSchema = z.object({
  taskId: z.string().optional(),
})

export const Route = createFileRoute("/recommendation/energy/$id")({
  validateSearch: searchSchema,
  loader: ({ context, params }) =>
    loadQuery<EnergyRecommendationPageQueryType>(
      context.relayEnvironment(),
      EnergyRecommendationPageQuery,
      {
        dealCardId: params.id,
        skipHousehold: !context.authState.isAuthenticated,
      },
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Recommendations/EnergyRecommendationPage/EnergyRecommendationPage"
      ),
  ),
})
