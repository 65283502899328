/**
 * @generated SignedSource<<4dafea008630e3922a5de900a5e444c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type UserNotificationPriority = "GENERIC" | "IMPORTANT" | "URGENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserNotification_notification$data = {
  readonly __typename: string;
  readonly id: string;
  readonly priority: UserNotificationPriority | null | undefined;
  readonly read: boolean | null | undefined;
  readonly savingsStatement?: {
    readonly id: string;
  } | null | undefined;
  readonly sentAt: string | null | undefined;
  readonly task?: {
    readonly dealCardExpiresAfterDays?: number | null | undefined;
    readonly id: string;
    readonly quest: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useIsTypeformUserTask_userTask">;
  } | null | undefined;
  readonly " $fragmentType": "UserNotification_notification";
};
export type UserNotification_notification$key = {
  readonly " $data"?: UserNotification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNotification_notification">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "useIsTypeformUserTask_userTask"
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "quest",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "task",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dealCardExpiresAfterDays",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNotification_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "EnergyDocumentUploadUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "task",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "ReviewEnergyRecommendationUserTask",
              "abstractKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "ReviewEnergyRecommendationUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "task",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "ReviewMobileRecommendationUserTask",
              "abstractKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "ReviewMobileRecommendationUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "task",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "ReviewBroadbandRecommendationUserTask",
              "abstractKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "ReviewBroadbandRecommendationUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "SubmitBankAccountDetailsUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SavingsStatement",
          "kind": "LinkedField",
          "name": "savingsStatement",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "SavingsStatementCreatedUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "BroadbandWizardUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "MobileWizardUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "MortgageWizardUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "UpdateHouseholdPreferencesUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "EnergyWizardUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "DelegateManagementOfServicesUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "ConnectEmailUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "ConnectWhatsAppUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "TypeformDataCollectionUserNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "InviteHouseholdMembersUserNotification",
      "abstractKey": null
    }
  ],
  "type": "IUserNotification",
  "abstractKey": "__isIUserNotification"
};
})();

(node as any).hash = "8d977ddd4c930855b0e215cba19ae6f2";

export default node;
